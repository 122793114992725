<template>
  <v-card outlined>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("settings") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: productId },
              })
            "
            :disabled="loading"
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open product") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />

    <v-card-text class="mt-n6">
      <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
        <v-expansion-panels
          v-model="settings"
          v-if="details != null"
          class="mt-6"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              {{ $t("common") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="default_locale_id"
                    :name="$t('default language')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-select
                      v-model="details.default_locale_id"
                      :items="locales"
                      item-value="id"
                      item-text="caption"
                      name="default_locale_id"
                      :label="$t('default language')"
                      :loading="loadingLocales"
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="fallback_destination"
                    :name="$t('fallback destination')"
                    rules="phone"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.fallback_destination"
                      name="fallback_destination"
                      :label="$t('fallback destination')"
                      :hint="$t('fallback destination for all numbers')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="call_filter_id"
                    :name="$t('call filter')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-select
                      v-model="details.call_filter_id"
                      :items="callFilters"
                      name="call_filter_id"
                      :label="$t('call filter')"
                      :hint="
                        details && details.call_filter_id
                          ? $t('call_filter_hint_' + details.call_filter_id)
                          : ''
                      "
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                    >
                      <template v-slot:item="{ item, index }">
                        {{ $t(item) }}
                      </template>
                      <template v-slot:selection="{ item, index }">
                        {{ $t(item) }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="saveCommon"
                  :loading="loading"
                  :disabled="invalid || loading"
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="quotasAndFunctions.allow_teams_settings">
            <v-expansion-panel-header class="text-h6">
              {{ $t("MS Teams settings") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="ms_teams_enabled"
                    :name="$t('enable MS Teams')"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-switch
                      v-model="details.ms_teams_enabled"
                      name="ms_teams_enabled"
                      :label="$t('enable MS Teams')"
                      :error-messages="errors"
                      :class="classes"
                      :disabled="!quotasAndFunctions.allow_teams_settings"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <validation-provider
                    vid="ms_teams_text_entry"
                    :name="$t('MS Teams TXT entry')"
                    rules="required"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.ms_teams_text_entry"
                      name="ms_teams_text_entry"
                      :label="$t('MS Teams TXT entry')"
                      :hint="$t('MS Teams TXT entry')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !quotasAndFunctions.allow_teams_settings ||
                        !details.ms_teams_enabled
                      "
                      append-icon="info"
                    >
                      <v-tooltip slot="append" top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-information</v-icon>
                        </template>
                        <span>{{ $t("msTeamsTextEntryHint") }}</span>
                      </v-tooltip>
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    vid="ms_teams_dns_name"
                    :name="$t('MS Teams DNS name')"
                    rules="required"
                    v-slot="{ errors, valid, dirty, classes }"
                  >
                    <v-text-field
                      v-model="details.ms_teams_dns_name"
                      name="ms_teams_dns_name"
                      :label="$t('MS Teams DNS name')"
                      :hint="$t('MS Teams DNS name')"
                      persistent-hint
                      :error-messages="errors"
                      :success="dirty && valid"
                      :class="classes"
                      single-line
                      :clearable="!isMobile"
                      :disabled="
                        !quotasAndFunctions.allow_teams_settings ||
                        !details.ms_teams_enabled
                      "
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    v-model="details.service_user_name"
                    name="service_user_name"
                    :label="$t('service username')"
                    :hint="$t('service username')"
                    persistent-hint
                    single-line
                    disabled
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="details.service_user_password"
                    name="service_user_password"
                    :label="$t('service password')"
                    :hint="$t('service password')"
                    persistent-hint
                    single-line
                    disabled
                  />
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="saveMsTeams"
                  :loading="loading"
                  :disabled="
                    !quotasAndFunctions.allow_teams_settings ||
                    invalid ||
                    loading
                  "
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="quotasAndFunctions.allow_rule_sets">
            <v-expansion-panel-header class="text-h6">
              {{ $t("block rules") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-chip-group
                :value="activeRulesetIndexes"
                active-class="green--text text--darken-2"
                multiple
                column
              >
                <v-chip
                  v-for="(ruleset, index) in rulesets"
                  :key="index"
                  class="ma-2"
                  @click="ruleset.active = !ruleset.active"
                  :disabled="!quotasAndFunctions.allow_rule_sets"
                  outlined
                  filter
                >
                  {{ ruleset.caption }}
                </v-chip>
              </v-chip-group>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="saveRuleSets"
                  :loading="loading"
                  :disabled="invalid || loading || !rulesetChanged"
                  text
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              {{ $t("phone numbers") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <data-table-extended
                :headers="headersNumbers"
                :items="numbers"
                :loading="loadingNumbers"
                :disable-sort="loadingNumbers"
                :mobile-breakpoint="mbreakpoint"
                show-expand
                sort-by="row"
                :hide-default-footer="numbers.length <= 15"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [15, 50],
                }"
                open-expand-by-click-row
              >
                <template v-slot:item.valid_from="{ item }">
                  <div class="monospace text-no-wrap">
                    {{
                      item.valid_from != null
                        ? formatDateTime(item.valid_from)
                        : "-"
                    }}
                  </div>
                </template>
                <template v-slot:item.valid_to="{ item }">
                  <div class="monospace text-no-wrap">
                    {{
                      item.valid_to != null
                        ? formatDateTime(item.valid_to)
                        : "-"
                    }}
                  </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <phone-number-details
                      :product-id="productId"
                      :phone-id="phoneId"
                      :number-id="item.id"
                      :number="item.phone_number"
                      :quotas-and-functions="quotasAndFunctions"
                      :locales="locales"
                    />
                  </td>
                </template>
              </data-table-extended>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="quotasAndFunctions.allowed_black_list">
            <v-expansion-panel-header class="text-h6">
              {{ $t("blacklists") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <data-table-extended
                :headers="headersBlacklist"
                :items="blacklist"
                :loading="loadingBlacklist"
                :disable-sort="loadingBlacklist"
                :mobile-breakpoint="mbreakpoint"
                show-expand
                single-expand
                :expanded.sync="expanded"
                sort-by="row"
                :hide-default-footer="blacklist.length <= 15"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [15, 50],
                }"
                open-expand-by-click-row
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <phone-black-list-entry
                      :product-id="productId"
                      :phone-id="phoneId"
                      :entry="item"
                      @cancel="
                        updateBlackList($event, 'cancel');
                        expanded = [];
                      "
                      @deleted="
                        updateBlackList($event, 'deleted');
                        expanded = [];
                      "
                      @changed="updateBlackList($event, 'changed')"
                      @created="updateBlackList($event, 'created')"
                    />
                  </td>
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      @click="addBlackList()"
                      :disabled="
                        !quotasAndFunctions.allowed_black_list ||
                        hasNewBlackListEntry
                      "
                      text
                    >
                      {{ $t("new entry") }}
                    </v-btn>
                  </v-toolbar>
                </template>
              </data-table-extended>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="quotasAndFunctions.allow_mobile_devices">
            <v-expansion-panel-header class="text-h6">
              {{ $t("mobile devices") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <data-table-extended
                :headers="headersMobileDevices"
                :items="mobileDevices"
                :loading="loadingMobileDevices"
                :disable-sort="loadingMobileDevices"
                :mobile-breakpoint="mbreakpoint"
                show-expand
                single-expand
                :expanded.sync="expanded"
                sort-by="row"
                :hide-default-footer="mobileDevices.length <= 15"
                :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageOptions: [15, 50],
                }"
                open-expand-by-click-row
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <phone-mobile-device-entry
                      :product-id="productId"
                      :phone-id="phoneId"
                      :entry="item"
                      :numbers="numbers"
                      @cancel="
                        updateMobileDevice($event, 'cancel');
                        expanded = [];
                      "
                      @deleted="
                        updateMobileDevice($event, 'deleted');
                        expanded = [];
                      "
                      @changed="
                        updateMobileDevice($event, 'changed');
                        expanded = [];
                      "
                      @created="
                        updateMobileDevice($event, 'created');
                        expanded = [$event];
                      "
                      @keysrenewed="updateMobileDevice($event, 'keysrenewed')"
                    />
                  </td>
                </template>
                <template v-slot:top>
                  <v-row align="center" justify="center">
                    <v-col cols="9">
                      <v-btn
                        href="https://play.google.com/store/apps/details?id=ch.nexphone.ipcall.android&hl=de"
                        target="_blank"
                        text
                      >
                        <v-icon large>mdi-android</v-icon>
                        <span>{{ $t("ipcall-app-android") }}</span>
                      </v-btn>
                      <v-btn
                        href="https://apps.apple.com/ch/app/nexphone-ag-ipcall/id979545853"
                        target="_blank"
                        text
                      >
                        <v-icon large>mdi-apple</v-icon>
                        <span>{{ $t("ipcall-app-apple") }}</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-toolbar flat>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="addMobileDevice()"
                          :disabled="
                            !quotasAndFunctions.allow_mobile_devices ||
                            hasNewMobileDeviceEntry
                          "
                          text
                        >
                          {{ $t("new entry") }}
                        </v-btn>
                      </v-toolbar>
                    </v-col>
                  </v-row>
                </template>
              </data-table-extended>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import PhoneBlackListEntry from "@/components/services/phone/PhoneBlackListEntry";
import PhoneMobileDeviceEntry from "@/components/services/phone/PhoneMobileDeviceEntry";
import PhoneNumberDetails from "@/components/services/phone/PhoneNumberDetails";
import DataTableExtended from "@/components/basics/DataTableExtended";

var quotasAndFunctionsDefaults = {
  allowed_black_list: false,
  allow_emergency_configuration: false,
  allow_mgcp_settings: false,
  allow_mobile_devices: false,
  allow_redirects: false,
  allow_rule_sets: false,
  allow_sip_settings: false,
  allow_teams_settings: false,
  allow_top_stop: false,
  allow_voice_mail_settings: false,
  has_fax2_mail: false,
  fax_number_quota: null,
  soft_phone_quota: null,
  top_stop_max_amount: null,
  top_stop_min_amount: null,
  use_special_fax_trunk: false,
};

export default {
  name: "PhoneBaseDetails",
  components: {
    BooleanValue,
    DataTableExtended,
    PhoneBlackListEntry,
    PhoneMobileDeviceEntry,
    PhoneNumberDetails,
  },
  mixins: [showErrors, formatDateTime, isMobile],
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    phoneId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    expanded: [],
    loading: false,
    details: null,
    settings: 0,
    callFilters: ["OFF", "LIGHT", "FULL"],
    loadingLocales: false,
    locales: [],
    loadingNumbers: false,
    numbers: [],
    loadingRuleSets: false,
    rulesets: [],
    initialActiveRulesetIndexes: [],
    loadingBlacklist: false,
    blacklist: [],
    quotasAndFunctions: { ...quotasAndFunctionsDefaults },
    loadingQuotasAndFunctions: false,
    mobileDevices: [],
    loadingMobileDevices: false,
  }),
  computed: {
    msTeamsDnsNameDefault() {
      if (this.details != null)
        return "t" + (this.details.instance_id || "") + ".sip4teams.ch";
      return "";
    },
    activeRulesetIndexes() {
      var i = 0;
      return this.rulesets
        .map(function (ruleset) {
          i = i + 1;
          return ruleset.active ? i - 1 : null;
        })
        .filter((index) => index != null);
    },
    rulesetChanged() {
      return (
        this.activeRulesetIndexes.sort().toString() !=
        this.initialActiveRulesetIndexes.sort().toString()
      );
    },
    hasNewBlackListEntry() {
      return (
        this.blacklist.filter(function (e) {
          return e.id == null;
        }).length > 0
      );
    },
    hasNewMobileDeviceEntry() {
      return (
        this.mobileDevices.filter(function (e) {
          return e.id == null;
        }).length > 0
      );
    },
    headersNumbers() {
      return [
        {
          text: this.$i18n.t("phone number"),
          value: "phone_number",
        },
        {
          text: this.$i18n.t("valid from"),
          value: "valid_from",
        },
        {
          text: this.$i18n.t("valid to"),
          value: "valid_to",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headersBlacklist() {
      return [
        {
          text: this.$i18n.t("content"),
          value: "content",
        },
        {
          text: this.$i18n.t("remarks"),
          value: "remarks",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    headersMobileDevices() {
      return [
        {
          text: this.$i18n.t("device name"),
          value: "name",
        },
        {
          text: this.$i18n.t("phone number"),
          value: "number",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
  },
  watch: {
    phoneId: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getDetails();
          this.getQuotasAndFunctions();
          this.getLocales();
          this.getNumbers();
          this.getRuleSets();
          this.getBlacklist();
          this.getMobileDevices();
        } else {
          this.details = null;
          this.quotasAndFunctions = { ...quotasAndFunctionsDefaults };
          this.numbers = [];
          this.rulesets = [];
          this.blacklist = [];
          this.mobileDevices = [];
        }
      },
    },
    "details.ms_teams_enabled": function (value) {
      if (value && this.details != null && !this.details.ms_teams_dns_name)
        this.details.ms_teams_dns_name = this.msTeamsDnsNameDefault;
    },
  },
  methods: {
    getDetails() {
      var that = this;
      this.loading = true;
      this.$http
        .get("services/phone/" + this.productId + "/phones/" + this.phoneId)
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getLocales() {
      var that = this;
      this.loadingLocales = true;
      this.$http
        .get("services/phone/locales")
        .then((response) => {
          that.locales = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingLocales = false;
        });
    },
    getQuotasAndFunctions() {
      var that = this;
      this.loadingQuotasAndFunctions = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/allowed-quotas-and-functions"
        )
        .then((response) => {
          that.quotasAndFunctions = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingQuotasAndFunctions = false;
        });
    },
    saveCommon() {
      var that = this;
      this.loading = true;
      var params = {
        default_locale_id: this.details.default_locale_id,
        fallback_destination: this.details.fallback_destination || null,
        call_filter_id: this.details.call_filter_id,
      };

      this.$http
        .patch(
          "services/phone/" + this.productId + "/phones/" + this.phoneId,
          params
        )
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    saveMsTeams() {
      var that = this;
      this.loading = true;
      var params = {
        ms_teams_enabled: this.details.ms_teams_enabled,
      };
      if (this.details.ms_teams_enabled) {
        params.ms_teams_text_entry = this.details.ms_teams_text_entry;
        params.ms_teams_dns_name = this.details.ms_teams_dns_name;
        /** currently read only, maybe change it in newer versions
        params.service_user_name = this.details.service_user_name;
        params.service_user_password = this.details.service_user_password;
        */
      }
      this.$http
        .patch(
          "services/phone/" + this.productId + "/phones/" + this.phoneId,
          params
        )
        .then((response) => {
          that.details = response.data;
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
    getNumbers() {
      var that = this;
      this.loadingNumbers = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/numbers"
        )
        .then((response) => {
          that.numbers = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingNumbers = false;
        });
    },
    getRuleSets() {
      var that = this;
      this.loadingRuleSets = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/rulesets"
        )
        .then((response) => {
          that.rulesets = response.data;
          that.initialActiveRulesetIndexes = [...that.activeRulesetIndexes];
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingRuleSets = false;
        });
    },
    saveRuleSets() {
      var that = this;
      this.loading = true;
      var requests = this.rulesets.map(function (ruleset) {
        return that.$http.patch(
          "services/phone/" +
            that.productId +
            "/phones/" +
            that.phoneId +
            "/rulesets/" +
            ruleset.id,
          {
            active: ruleset.active,
          }
        );
      });
      axios
        .all(requests)
        .then(axios.spread((...responses) => {}))
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loading = false;
          that.initialActiveRulesetIndexes = [...that.activeRulesetIndexes];
        });
    },
    getBlacklist() {
      var that = this;
      this.loadingBlacklist = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/blacklist"
        )
        .then((response) => {
          that.blacklist = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingBlacklist = false;
        });
    },
    updateBlackList(entry, action) {
      this.blacklist = this.blacklist
        .map(function (e) {
          if (e.id != null && e.id == entry.id) return entry;
          else if (action == "created" && e.id == null) return entry;
          else return e;
        })
        .filter(function (e) {
          if (action == "deleted" && e.id == entry.id) return false;
          else return e.id != null;
        });
    },
    addBlackList() {
      var newEntry = {
        id: null,
        content: null,
        remarks: null,
      };
      this.blacklist = [newEntry, ...this.blacklist];
      this.expanded = [newEntry];
    },
    getMobileDevices() {
      var that = this;
      this.loadingMobileDevices = true;
      this.$http
        .get(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/mobile-devices"
        )
        .then((response) => {
          that.mobileDevices = response.data;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingMobileDevices = false;
        });
    },
    updateMobileDevice(entry, action) {
      this.mobileDevices = this.mobileDevices
        .map(function (e) {
          if (e.id != null && e.id == entry.id) return entry;
          else if (action == "created" && e.id == null) return entry;
          else return e;
        })
        .filter(function (e) {
          if (action == "deleted" && e.id == entry.id) return false;
          else return e.id != null;
        });
    },
    addMobileDevice() {
      var newEntry = {
        id: null,
        name: null,
        number: null,
        number_id: null,
        qrcode: null,
      };
      this.mobileDevices = [newEntry, ...this.mobileDevices];
      this.expanded = [newEntry];
    },
  },
};
</script>

<i18n>
{
  "en": {
    "settings": "Settings",
    "open product": "open product",
    "provision status": "Provision status",
    "allowed bell groups": "Allowed bell groups",
    "allowed conference rooms": "Allowed conference rooms",
    "allowed cti clients": "Allowed CTI clients",
    "allowed fax number": "Allowed fax number",
    "allowed locations": "Allowed locations",
    "allowed soft phones": "Allowed soft phones",
    "allowed subscribers": "Allowed subscribers",
    "common": "Common",
    "MGCP settings": "MGCP settings",
    "MGCP device": "MGCP device",
    "MS Teams settings": "MS Teams settings",
    "enable MS Teams": "Enable MS Teams",
    "MS Teams TXT entry": "MS Teams TXT entry for DNS verification",
    "msTeamsTextEntryHint": "Enter the value that is given to you in the portal for MS Teams administration.",
    "MS Teams DNS name": "MS Teams DNS name (connection target)",
    "service username": "Service username",
    "service password": "Service password",
    "fallback destination": "Fallback destination number",
    "fallback destination for all numbers": "Fallback destination for all numbers",
    "call_filter_hint_OFF": "Incoming calls are not filtered.",
    "call_filter_hint_LIGHT": "Incoming calls that have been clearly identified as advertising calls are blocked.",
    "call_filter_hint_FULL": "Incoming calls that are presumed to be solicitation calls are blocked.",
    "send AOC": "Send AOC",
    "always use media server": "Always use media server",
    "special arrangement": "Activate special arrangement",
    "call filter": "Call filter",
    "OFF": "switched off",
    "LIGHT": "partially",
    "FULL": "completely",
    "MAC address": "MAC address",
    "top stop": "Top stop",
    "enable top stop": "Enable top stop",
    "max. amount": "Maximal amount",
    "alert at %": "Alert at %",
    "not use emergency location": "Nomadischer Teilnehmer",
    "emergency community": "Community for mergency routing",
    "default language": "Default language",
    "malicious caller ID": "Malicious caller ID",
    "exclude from CPE provisioning": "Exclude from CPE provisioning",
    "number of channels": "Number of channels",
    "has planned MAC address change": "Has planned MAC address change",
    "planned device MAC address": "Planned device MAC address",
    "setup date": "Setup date",
    "deletion date": "Deletion date",
    "phone numbers": "Phone numbers",
    "block rules": "Block rules",
    "blacklists": "Blacklists",
    "phone number": "phone number",
    "valid from": "valid from",
    "valid to": "valid to",
    "content": "content",
    "remarks": "remarks",
    "new entry": "New entry",
    "mobile devices": "Mobile devices (IPCall)",
    "device name": "Device name",
    "phone number": "Phone number",
    "ipcall-app-android": "IPCall app for Android",
    "ipcall-app-apple": "IPCall app for iOS"
  },
  "de": {
    "settings": "Einstellungen",
    "open product": "öffne Produkt",
    "provision status": "Bereitstellungsstatus",
    "allowed bell groups": "Erlaubte Gruppenrufnummern",
    "allowed conference rooms": "Erlaubte Konferenzräume",
    "allowed cti clients": "Erlaubte CTI Clients",
    "allowed fax number": "Erlaubte Fax Nummern",
    "allowed locations": "Erlaubte Standorte",
    "allowed soft phones": "Erlaubte Soft Phones",
    "allowed subscribers": "Erlaubte Abonnenten",
    "common": "Allgemein",
    "MGCP settings": "MGCP Einstellungen",
    "MGCP device": "MGCP Gerät",
    "MS Teams settings": "MS Teams Einstellungen",
    "enable MS Teams": "MS Teams aktivieren",
    "MS Teams TXT entry": "MS Teams TXT Eintrag für DNS Verifikation",
    "msTeamsTextEntryHint": "Geben Sie hier den Wert an, der Ihnen im Portal für die MS Teams Administration angegeben wird.",
    "MS Teams DNS name": "MS Teams DNS Name (Verbindungsziel)",
    "service username": "Dienst Benutzername",
    "service password": "Dienst Passwort",
    "fallback destination": "Rufnummer wenn nicht erreichbar",
    "fallback destination for all numbers": "Fallback Rufnummer für alle Rufnummern",
    "call_filter_hint_OFF": "Es werden keine eingehenden Anrufe gefiltert.",
    "call_filter_hint_LIGHT": "Es werden eingehende Anrufe blockiert, welche eindeutig als Werbeanrufe identifiziert wurden.",
    "call_filter_hint_FULL": "Es werden eingehende Anrufe blockiert, von denen angenommen wird, dass es Werbeanrufe sind.",
    "send AOC": "Gebührenimplus senden",
    "always use media server": "Immer Medienserver verwenden",
    "special arrangement": "Special Arrangement aktivieren",
    "call filter": "Filtern von Anrufen",
    "OFF": "ausgeschaltet",
    "LIGHT": "teilweise",
    "FULL": "vollständig",
    "MAC address": "MAC Adresse",
    "top stop": "Top Stop",
    "enable top stop": "Top Stop aktivieren",
    "max. amount": "Maximaler Betrag",
    "alert at %": "Alarmieren bei %",
    "emergency community": "Gemeinde für Notruf Routing",
    "default language": "Default Sprache",
    "malicious caller ID": "Böseartige Anrufer ID",
    "exclude from CPE provisioning": "Von CPE-Bereitstellung ausschließen",
    "number of channels": "Anzahl Gesprächskanäle",
    "has planned MAC address change": "Hat geplante MAC Adressenänderung",
    "planned device MAC address": "Geplante Geräte MAC Adresse",
    "setup date": "Aufschaltdatum",
    "deletion date": "Abschaltdatum",
    "phone numbers": "Rufnummern",
    "block rules": "Sperren und Regeln",
    "blacklists": "Unerwünschte Anrufer",
    "phone number": "Telefonnummer",
    "valid from": "gültig von",
    "valid to": "gültig bis",
    "content": "Inhalt",
    "remarks": "Bemerkungen",
    "new entry": "Neuer Eintrag",
    "mobile devices": "Mobile Geräte (IPCall)",
    "device name": "Gerätename",
    "phone number": "Telefonnumber",
    "ipcall-app-android": "IPCall App for Android",
    "ipcall-app-apple": "IPCall App for iOS"
  }
}
</i18n>
