<template>
  <v-card :loading="loadingUpdate" flat class="my-2" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <validation-provider
              vid="name"
              :name="$t('name')"
              rules="required|max:20"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="name"
                name="name"
                :label="$t('name')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                single-line
                :clearable="!isMobile"
                :disabled="disabled"
              />
            </validation-provider>
          </v-col>
          <v-col cols="6">
            <validation-provider
              vid="name"
              :name="$t('name')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-select
                v-model="number"
                :items="numbers"
                item-text="phone_number"
                item-value="id"
                :label="$t('phone number')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                single-line
                :clearable="!isMobile"
                :disabled="disabled || entry.id != null"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row v-if="showQrCode">
          <v-col cols="12">
            <qr-code :text="entry.qrcode" :size="120" error-level="L" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="deleteEntry()"
          :loading="loadingDelete"
          :disabled="disabled || loading"
          text
        >
          {{ $t("delete") }}
        </v-btn>
        <v-btn
          v-if="entry.id != null"
          @click="renewKeys()"
          :loading="loadingRenewKeys"
          :disabled="disabled || loadingDelete"
          text
        >
          {{ $t("renew keys") }}
        </v-btn>

        <v-btn
          v-if="entry.id != null && !showQrCode"
          @click="showQrCode = true"
          :disabled="disabled || loading"
          text
        >
          {{ $t("show QR code") }}
        </v-btn>
        <v-btn
          v-else-if="entry.id != null && showQrCode"
          @click="showQrCode = false"
          :disabled="disabled || loading"
          text
        >
          {{ $t("hide QR code") }}
        </v-btn>

        <v-spacer />
        <v-btn @click="$emit('cancel', entry)" :disabled="loading" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="entry.id == null ? createEntry() : updateEntry()"
          :loading="entry.id == null ? loadingCreate : loadingUpdate"
          :disabled="disabled || invalid || loading"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "PhoneMobileDeviceEntry",
  mixins: [showErrors, isMobile],
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    phoneId: {
      type: [Number, String],
      required: true,
    },
    entry: {
      type: Object,
      require: true,
    },
    numbers: {
      type: Array,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data: () => ({
    name: null,
    number: null,
    showQrCode: false,
    loadingRetrieve: false,
    loadingCreate: false,
    loadingUpdate: false,
    loadingDelete: false,
    loadingRenewKeys: false,
  }),
  computed: {
    loading() {
      return (
        this.loadingCreate ||
        this.loadingUpdate ||
        this.loadingDelete ||
        this.loadingRenewKeys ||
        this.loadingRetrieve
      );
    },
  },
  watch: {
    entry: {
      immediate: true,
      handler(value) {
        if (value) {
          this.name = value.name;
          this.number = value.number_id;
        }
      },
    },
  },
  methods: {
    updateEntry() {
      var that = this;
      this.loadingUpdate = true;
      this.$http
        .put(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/mobile-devices/" +
            this.entry.id,
          {
            name: this.name,
          }
        )
        .then((response) => {
          that.$emit("changed", response.data);
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingUpdate = false;
        });
    },
    renewKeys() {
      var that = this;
      this.loadingRenewKeys = true;
      this.$http
        .put(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/mobile-devices/" +
            this.entry.id,
          {
            name: this.entry.name,
            renew_keys: true,
          }
        )
        .then((response) => {
          that.$emit("keysrenewed", response.data);
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingRenewKeys = false;
        });
    },
    createEntry() {
      var that = this;
      this.loadingCreate = true;
      this.$http
        .post(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/mobile-devices",
          {
            name: this.name,
            number_id: this.number,
          }
        )
        .then((response) => {
          that.$emit("created", response.data);
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingCreate = false;
        });
    },
    deleteEntry() {
      var that = this;
      if (this.entry.id) {
        this.loadingDelete = true;
        this.$http
          .delete(
            "services/phone/" +
              this.productId +
              "/phones/" +
              this.phoneId +
              "/mobile-devices/" +
              this.entry.id
          )
          .then((response) => {
            that.$emit("deleted", this.entry);
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loadingDelete = false;
          });
      } else that.$emit("deleted", this.entry);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "phone number": "Phone number",
    "name": "Name",
    "delete": "Delete",
    "cancel": "Cancel",
    "save": "Save",
    "renew keys": "Renew keys",
    "show QR code": "Show QR code",
    "hide QR code": "Show QR code"
  },
  "de": {
    "phone number": "Telefonnummer",
    "name": "Name",
    "delete": "Löschen",
    "cancel": "Abbrechen",
    "save": "Speichern",
    "renew keys": "Schlüssel erneuern",
    "show QR code": "QR code anzeigen",
    "hide QR code": "QR code ausblenden"
  }
}
</i18n>
