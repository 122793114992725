<template>
  <v-card :loading="loading" flat class="my-2" outlined>
    <validation-observer ref="obs" v-slot="{ errors, invalid, validated }">
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-select v-model="type" :items="types" :label="$t('content type')">
              <template v-slot:item="{ item, index }">
                {{ $t(item) }}
              </template>
              <template v-slot:selection="{ item, index }">
                {{ $t(item) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="content"
              :name="$t('content')"
              rules="required"
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="content"
                name="content"
                :label="$t('content')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                single-line
                :clearable="!isMobile"
                :disabled="disabled"
              />
            </validation-provider>
          </v-col>
          <v-col cols="4">
            <validation-provider
              vid="remarks"
              :name="$t('remarks')"
              rules=""
              v-slot="{ errors, valid, dirty, classes }"
            >
              <v-text-field
                v-model="remarks"
                name="remarks"
                :label="$t('remarks')"
                :error-messages="errors"
                :success="dirty && valid"
                :class="classes"
                single-line
                :clearable="!isMobile"
                :disabled="disabled"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="deleteEntry()"
          :loading="loadingDelete"
          :disabled="disabled || loading"
          text
        >
          {{ $t("delete") }}
        </v-btn>
        <v-spacer />
        <v-btn @click="$emit('cancel', entry)" :disabled="loading" text>
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="id == null ? createEntry() : updateEntry()"
          :loading="id == null ? loadingCreate : loadingUpdate"
          :disabled="disabled || invalid || loading"
          text
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";

export default {
  name: "PhoneBlackListEntry",
  mixins: [showErrors, isMobile],
  props: {
    productId: {
      type: [Number, String],
      required: true,
    },
    phoneId: {
      type: [Number, String],
      required: true,
    },
    entry: {
      type: Object,
      require: true,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data: () => ({
    id: null,
    content: null,
    remarks: null,
    type: "exact",
    types: ["exact", "starts_with", "ends_with"],
    loadingUpdate: false,
    loadingCreate: false,
    loadingDelete: false,
  }),
  watch: {
    entry: {
      immediate: true,
      handler(value) {
        this.parseEntry(value);
      },
    },
  },
  computed: {
    loading() {
      return this.loadingUpdate || this.loadingCreate || this.loadingDelete;
    },
    newEntry() {
      return {
        id: this.id || null,
        content:
          this.type == "starts_with"
            ? this.content + ".*"
            : this.type == "ends_with"
            ? ".*" + this.content
            : this.content,
        remarks: this.remarks || null,
      };
    },
  },
  methods: {
    parseEntry(value) {
      if (value != null) {
        if (value.content != null && value.content.startsWith(".*")) {
          this.content = value.content.replace(".*", "");
          this.type = "ends_with";
        } else if (value.content != null && value.content.endsWith(".*")) {
          this.content = value.content.replace(".*", "");
          this.type = "starts_with";
        } else {
          this.content = value.content;
          this.type = "exact";
        }
        this.remarks = value.remarks;
        this.id = value.id || null;
      } else {
        this.content = null;
        this.remarks = null;
        this.type = "exact";
        this.id = null;
      }
    },
    updateEntry() {
      var that = this;
      this.loadingUpdate = true;
      this.$http
        .put(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/blacklist/" +
            this.id,
          this.newEntry
        )
        .then((response) => {
          that.parseEntry(response.data);
          that.$emit("changed", this.newEntry);
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingUpdate = false;
        });
    },
    createEntry() {
      var that = this;
      this.loadingCreate = true;
      this.$http
        .post(
          "services/phone/" +
            this.productId +
            "/phones/" +
            this.phoneId +
            "/blacklist",
          this.newEntry
        )
        .then((response) => {
          that.parseEntry(response.data);
          that.$emit("created", this.newEntry);
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loadingCreate = false;
        });
    },
    deleteEntry() {
      var that = this;
      if (this.id) {
        this.loadingDelete = true;
        this.$http
          .delete(
            "services/phone/" +
              this.productId +
              "/phones/" +
              this.phoneId +
              "/blacklist/" +
              this.id
          )
          .then((response) => {
            that.$emit("deleted", this.entry);
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loadingDelete = false;
          });
      } else that.$emit("deleted", this.entry);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "content type": "Content type",
    "content": "Content",
    "remarks": "Remarks",
    "delete": "Delete",
    "save": "Save",
    "cancel": "Cancel",
    "exact": "exact number",
    "starts_with": "number starts with",
    "ends_with": "number ends with"
  },
  "de": {
    "content type": "Inhaltstyp",
    "content": "Inhalt",
    "remarks": "Bemerkungen",
    "delete": "Löschen",
    "save": "Speichern",
    "cancel": "Abbruch",
    "exact": "genaue Nummer",
    "starts_with": "Nummer beginnt mit",
    "ends_with": "Nummer endet mit"
  }
}
</i18n>
